import { Injector } from '@angular/core';

// @dynamic
export class AppInjector {
  private static instance: AppInjector;
  private injector?: Injector;

  public static getInstance(): AppInjector {
    if (!AppInjector.instance) {
      AppInjector.instance = new AppInjector();
    }

    return AppInjector.instance;
  }

  public setInjector(injector: Injector): void {
    this.injector = injector;
  }

  public getInjector(): Injector {
    if (!this.injector) {
      throw new Error('AppInjector.getInstance().setInjector(appRef.injector) 호출을 해주세요.');
    }
    return this.injector;
  }

  public getInjectorWithUncheck(): Injector | undefined {
    return this.injector;
  }
}
